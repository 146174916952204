import { amber } from "@material-ui/core/colors";

const getColor = {
  primary: "#0533F2",
  secondary: "#FF661F",
  error: "#C41E24",
  warning: amber[800],
  primaryUnderlay: "#530087",
  success: "#1D911D",
  publicPrimary: "#307b96",
  publicSecondary: "#fcfcc6",
};
export default getColor;
