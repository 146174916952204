import { decodeToken } from "react-jwt";
import api from "../api/api";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("superadmin-auth")) {
      return dispatch({
        type: "AUTH_SUPER_ADMIN",
      });
    } else if (localStorage.getItem("admin-auth")) {
      return dispatch({
        type: "AUTH_ADMIN",
      });
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchDecoded = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  const decoded = await decodeToken(token);
  return dispatch({
    type: "FETCH_DECODED",
    payload: decoded,
  });
};

export const fetchProfile = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }

  try {
    const decoded = await decodeToken(token);
    const response = await api.get(`/api/users/${decoded.id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return dispatch({
      type: "FETCH_PROFILE",
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  }
};

export const fetchNablLogo = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/settings/nabl-logo`);

    return dispatch({
      type: "FETCH_NABL_LOGO",
      payload: response.data.data,
    });
  } catch (error) {
    return;
  }
};

export const fetchDefaults = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }

  try {
    const decoded = await decodeToken(token);
    const response = await api.get(`/api/defaults/${decoded.laboratory}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    await Promise.all(
      dispatch({
        type: "FETCH_MY_LAB",
        payload: response.data.myLab,
      }),
      dispatch({
        type: "FETCH_SOURCE_TYPE",
        payload: response.data.source_type,
      }),
      dispatch({
        type: "FETCH_TEST_TYPE",
        payload: response.data.test_type,
      }),
      dispatch({
        type: "FETCH_PARAMETERS",
        payload: response.data.parameters,
      }),
      dispatch({
        type: "FETCH_LABS",
        payload: response.data.labs,
      })
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
    return dispatch({
      type: "CLEAR_DASHBOARD",
    });
  }
};

export const logout = () => async (dispatch) => {
  window.location.href = "/user-login";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};
