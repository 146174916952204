const stringValues = {
  name: "PHED Laboratory Management System",
  short: "MPHED LAB",
  address1: "Khatla (Opposite Tennis Court)",
  address2: "Aizawl, Mizoram - 796001",
  email: "phedmizoram@yahoo.com",
  contact: "0389-2322244",
  fax: "0389-2301706",
};
export default stringValues;
