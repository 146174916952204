const reducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_PARAMETERS":
      return action.payload;
    case "ADD_PARAMETERS":
      return [...state, action.payload];
    case "UPDATE_PARAMETERS":
      return state.map((item) =>
        item.id === action.payload.id ? { ...action.payload } : item
      );
    case "REMOVE_PARAMETERS":
      return state.filter((s) => s.id !== action.payload);
    case "CLEAR_PARAMETERS":
      return [];
    default:
      return state;
  }
};
export default reducer;
