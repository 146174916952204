import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import {
  checkAuth,
  fetchDefaults,
  fetchDecoded,
  fetchProfile,
  fetchNablLogo,
} from "./actions";
import Navbar from "./components/Navbar";
import PublicWrapper from "./pages/shared/components/PublicWrapper";
const Home = lazy(() => import("./pages/shared/Home"));
const SearchTestResult = lazy(() => import("./pages/shared/SearchTestResult"));
const SampleResult = lazy(() => import("./pages/shared/SampleResult"));
const Login = lazy(() => import("./pages/shared/Login"));
const AboutUs = lazy(() => import("./pages/shared/AboutUs"));
const Contact = lazy(() => import("./pages/shared/Contact"));
const PrivacyPolicy = lazy(() => import("./pages/shared/PrivacyPolicy"));
const SuperAdminDashboard = lazy(() =>
  import("./pages/Dashboard/SuperAdminDashboard")
);
const AdminDashboard = lazy(() => import("./pages/Dashboard/AdminDashboard"));
const LaboratoryManagement = lazy(() =>
  import("./pages/LaboratoryManagement/LaboratoryManagement")
);
const LabParametersMapping = lazy(() =>
  import("./pages/LaboratoryManagement/LabParametersMapping")
);
const SourceType = lazy(() => import("./pages/SourceType/SourceType"));
const UserManagement = lazy(() =>
  import("./pages/UserManagement/UserManagement")
);
const SampleEntry = lazy(() => import("./pages/Samples/SampleEntry"));
const AssignTest = lazy(() => import("./pages/Samples/AssignTest"));
const MySample = lazy(() => import("./pages/Samples/MySample"));
const SampleTestReport = lazy(() => import("./pages/Samples/SampleTestReport"));
const AllSampleRecord = lazy(() => import("./pages/Samples/AllSampleRecord"));
const AnalysisReport = lazy(() =>
  import("./pages/AnalysisReport/AnalysisReport")
);
const ParameterWiseReport = lazy(() =>
  import("./pages/AnalysisReport/ParameterWiseReport")
);
const DefaultData = lazy(() => import("./pages/Settings/DefaultData"));
const AccountSettings = lazy(() =>
  import("./pages/AccountSettings/AccountSettings")
);
const NotFound = lazy(() => import("./NotFound"));

const App = (props) => {
  const {
    checkAuth,
    fetchDefaults,
    fetchDecoded,
    fetchProfile,
    fetchNablLogo,
    profile,
    auth,
  } = props;

  useEffect(() => {
    checkAuth();
    if (auth) fetchData();
  }, [auth]);
  useEffect(() => {
    fetchNablLogo();
  }, []);

  const fetchData = async () => {
    await Promise.all([fetchDecoded(), fetchProfile(), fetchDefaults()]);
  };

  const publicRoutes = [
    {
      path: "/",
      component: <Home />,
      isShowing: true,
    },
    {
      path: "/test-result",
      component: <SearchTestResult />,
      isShowing: true,
    },
    {
      path: "/test-result/:id",
      component: <SampleResult />,
      isShowing: true,
    },
    {
      path: "/user-login",
      component: <Login />,
      isShowing: true,
    },
    {
      path: "/privacy-policy",
      component: <PrivacyPolicy />,
      isShowing: true,
    },
    {
      path: "/about",
      component: <AboutUs />,
      isShowing: true,
    },
    {
      path: "/contact",
      component: <Contact />,
      isShowing: true,
    },
  ];

  const adminRoutes = [
    {
      path: "/",
      component: <AdminDashboard />,
      isShowing: true,
    },
    {
      path: "/sample-entry",
      component: <SampleEntry />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("A"))
          ? true
          : false,
    },
    {
      path: "/sample-entry/:start_date/:end_date",
      component: <SampleEntry />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("A"))
          ? true
          : false,
    },
    {
      path: "/assign-test",
      component: <AssignTest />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("C"))
          ? true
          : false,
    },
    {
      path: "/my-sample",
      component: <MySample />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("B"))
          ? true
          : false,
    },
    {
      path: "/my-sample/:start_date/:end_date",
      component: <MySample />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("B"))
          ? true
          : false,
    },
    {
      path: "/sample-test-report",
      component: <SampleTestReport />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("D"))
          ? true
          : false,
    },
    {
      path: "/sample-test-report/:start_date/:end_date",
      component: <SampleTestReport />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("D"))
          ? true
          : false,
    },
    {
      path: "/all-sample-record",
      component: <AllSampleRecord />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("F"))
          ? true
          : false,
    },
    {
      path: "/all-sample-record/:laboratory/:start_date/:end_date",
      component: <AllSampleRecord />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("F"))
          ? true
          : false,
    },
    {
      path: "/analysis-report",
      component: <AnalysisReport />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("E"))
          ? true
          : false,
    },
    {
      path: "/analysis-report/:laboratory/:start_date/:end_date/:area",
      component: <AnalysisReport />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("E"))
          ? true
          : false,
    },
    {
      path: "/parameter-wise-report",
      component: <ParameterWiseReport />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("E"))
          ? true
          : false,
    },
    {
      path: "/parameter-wise-report/:laboratory/:parameter/:start_date/:end_date/:min/:max/:area",
      component: <ParameterWiseReport />,
      isShowing:
        profile.role &&
        (profile.role.includes("X") || profile.role.includes("E"))
          ? true
          : false,
    },
    {
      path: "/account",
      component: <AccountSettings />,
      isShowing: true,
    },
  ];
  const superAdminRoutes = [
    {
      path: "/",
      component: <SuperAdminDashboard />,
      isShowing: true,
    },
    {
      path: "/analysis-report",
      component: <AnalysisReport />,
      isShowing: true,
    },
    {
      path: "/analysis-report/:laboratory/:start_date/:end_date/:area",
      component: <AnalysisReport />,
      isShowing: true,
    },
    {
      path: "/parameter-wise-report",
      component: <ParameterWiseReport />,
      isShowing: true,
    },
    {
      path: "/parameter-wise-report/:laboratory/:parameter/:start_date/:end_date/:min/:max/:area",
      component: <ParameterWiseReport />,
      isShowing: true,
    },
    {
      path: "/all-sample-record",
      component: <AllSampleRecord />,
      isShowing: true,
    },
    {
      path: "/all-sample-record/:laboratory/:start_date/:end_date",
      component: <AllSampleRecord />,
      isShowing: true,
    },
    {
      path: "/laboratory",
      component: <LaboratoryManagement />,
      isShowing: true,
    },
    {
      path: "/lab-params-mapping",
      component: <LabParametersMapping />,
      isShowing: true,
    },
    {
      path: "/source-type",
      component: <SourceType />,
      isShowing: true,
    },
    {
      path: "/user-management",
      component: <UserManagement />,
      isShowing: true,
    },
    {
      path: "/default-data",
      component: <DefaultData />,
      isShowing: true,
    },
    {
      path: "/account",
      component: <AccountSettings />,
      isShowing: true,
    },
  ];

  if (auth === 1) {
    //superadmin
    return (
      <Navbar>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {superAdminRoutes
              .filter((item) => item.isShowing)
              .map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  exact
                  element={item.component}
                />
              ))}
          </Routes>
        </Suspense>
      </Navbar>
    );
  } else if (auth === 2) {
    //admin
    return (
      <Navbar>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {adminRoutes
              .filter((item) => item.isShowing)
              .map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  exact
                  element={item.component}
                />
              ))}
          </Routes>
        </Suspense>
      </Navbar>
    );
  } else if (auth === 0) {
    //public
    return (
      <PublicWrapper>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {publicRoutes.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </PublicWrapper>
    );
  } else {
    return (
      <div
        style={{
          marginTop: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { auth: state.auth, profile: state.profile };
};
export default connect(mapStateToProps, {
  checkAuth,
  fetchDefaults,
  fetchDecoded,
  fetchProfile,
  fetchNablLogo,
})(App);
