import { combineReducers } from "redux";
import authReducer from "./authReducer";
import decodedReducer from "./decodedReducer";
import profileReducer from "./profileReducer";
import myLabReducer from "./myLabReducer";
import testTypeReducer from "./testTypeReducer";
import sourceTypeReducer from "./sourceTypeReducer";
import labsReducer from "./labsReducer";
import parametersReducer from "./parametersReducer";
import nablLogoReducer from "./nablLogoReducer";

export default combineReducers({
  auth: authReducer,
  decoded: decodedReducer,
  profile: profileReducer,
  myLab: myLabReducer,
  sourceType: sourceTypeReducer,
  testType: testTypeReducer,
  labs: labsReducer,
  parameters: parametersReducer,
  nabl_logo: nablLogoReducer,
});
